<template>
    <div :class="['MyItem', {'MyItem--required': required}]">
        <div class="MyItem__label">
            <slot name="label">
                <div>
                    <span v-if="required" class="MyItem__required">* </span>
                    {{ label }}
                </div>
                <span style="font-size: 12px; color: #969799;"><slot name="label-right"></slot></span>
            </slot>
        </div>
        <div class="MyItem__content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MyItem",
        props: {
            label: String,
            required: Boolean
        }
    }
</script>

<style lang="scss" scoped>
    .MyItem {
        margin-bottom: 16px;
        &__label {
            font-size: 14px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &__content {
            ::v-deep {
                .van-field {
                    background: #F7F8FA;
                    line-height: 20px;
                    &--disabled {
                        background: #EBEDF0;
                        .van-field__control {
                            -webkit-text-fill-color: #101010;
                        }
                    }
                }
            }
        }
        &__required {
            color: #F56C6C;
        }
    }
</style>
